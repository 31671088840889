import http from '@/services/http.service';

const apiUrl = '/indexes';

class IndexesService {
    static list(params) {
        return http.get(`${apiUrl}`, { params });
    }
    static create(payload) {
        return http.post(`${apiUrl}`, payload);
    }
    static edit(payload) {
        return http.put(`${apiUrl}/${payload.id}`, payload);
    }
    static get(id) {
        return http.get(`${apiUrl}/${id}`);
    }
    static delete(payload) {
        return http.delete(`${apiUrl}/${payload.id}`);
    }
}

export default IndexesService;
