<template>
  <div>
    <div v-if="openDetails" class="mt-4 font-weight-bold">
      <v-icon @click="closeView()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <toolbar
        :toolbarName="dynamicToolbarName"
        :openDetails="openDetails"
    />
    <form-dialog
        :dialog="formDialog"
        :formName="dynamicFormName"
    >
      <v-form ref="formIndex">
        <v-row>
          <v-col
              cols="12"
              md="4"
              sm="6"
          >
            <custom-label label="Nome"/>
            <simple-text-field v-model="payload.name"
                              required
                              placeholder="Nome do Índice"
            />
          </v-col>
          <v-col
              cols="12"
              md="4"
              sm="6"
          >
            <custom-label label="Registro"/>
            <simple-select :items="records"
                          v-model="selectedRecord"
                          placeholder="Selecione o Registro"
                          @input="getPhases"
                          itemText="type"
                          itemValue="id"
                          returnObject
                          :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
                          required
                          :rules="recordRules"
            />
          </v-col>
        </v-row>

        <v-row v-if="selectedRecord.type === 'Manejo'">
          <v-col
              cols="12"
              md="4"
              sm="6"
          >
            <custom-label label="Cultura"/>
            <simple-select :items="cultures"
                          v-model="payload.culture_id"
                          placeholder="Selecione a Cultura"
                          itemText="name"
                          itemValue="id"
                          :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
                          required
                          :rules="requiredRules"
                          @input="changeCulture()"
            />
          </v-col>
          <v-col
              cols="12"
              md="4"
              sm="6"
          >
            <custom-label label="Fase"/>
            <simple-select :items="phases"
                          :disabled="phases.length < 1"
                          v-model="payload.phase_id"
                          placeholder="Selecione a Fase"
                          itemText="name"
                          itemValue="id"
                          :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
                          required
                          :rules="phaseRules"
            />
          </v-col>
        </v-row>

        <v-row v-if="selectedRecord.id">
          <v-col
              cols="12"
              md="4"
              sm="6"
          >
            <custom-label label="Tipo"/>
            <simple-select :items="types"
                          v-model="payload.type"
                          placeholder="Selecione o Tipo"
                          itemText="text"
                          itemValue="text"
                          required
                          :rules="requiredRules"
                          :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
            />
          </v-col>

          <v-col
              cols="12"
              md="4"
              sm="6"
          >
            <custom-label label="Status"/>
            <simple-select :items="isActiveItems"
                          v-model="payload.status"
                          placeholder="Selecione o Status"
                          itemText="text"
                          itemValue="value"
                          :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
                          required
                          :rules="requiredRules"
            />
          </v-col>
        </v-row>
      </v-form>
    </form-dialog>

    <search-bar v-if="!openDetails" v-model="search" @externalFiltersClear="clearFilters" :noSecondaryInput="false">
      <simple-select :items="recordTypeItems"
                     v-model="recordTypeSearch"
                     class="mr-2"
                     placeholder="Registro"
                     @input="filterByRecordType"
                     itemText="name"
                     itemValue="name"
                     height="0"
                     returnObject
      />
    </search-bar>

    <div v-if="openDetails">
      <row-details>
        <v-row align="center">
          <v-col class="d-flex justify-start">
            <span class="font-weight-bold">Detalhes</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="d-flex justify-end">
            <v-icon @click="edit()"> mdi-pencil</v-icon>
          </v-col>
        </v-row>
        <v-row>
          <col-details v-if="payload.name != null" name="Nome" :item="payload.name"/>
          <col-details v-if="payload.phase != null" name="Registro" :item="payload.phase.record.type"/>
          <col-details v-if="payload.phase != null" name="Fase" :item="payload.phase.name"/>
        </v-row>
      </row-details>

    </div>

    <data-table
        :headers="headers"
        :fetching="fetching"
        :data="data"
        :objectName="objectName"
        :search="search.text"
        @loadAgain="loadAgain"
        v-else
    >

      <template v-slot:[`item.is_active`]="{ item }">
        {{ getStatus(item.is_active) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
            class="ml-4"
            small
            @click="view(item)"
        >
          mdi-information-outline
        </v-icon>

        <v-icon
          class="ml-4"
          small
          @click="deleteItem(item)">
          mdi-trash-can-outline
        </v-icon>
      </template>
    </data-table>

    <confirm-destroy-dialog
      :title="payload.name"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
import DataTable from "@/components/data-table/DataTable";
import Toolbar from "@/components/crud/Toolbar";
import FormDialog from "@/components/crud/FormDialog";
import RowDetails from "@/components/crud/Details";
import ColDetails from "@/components/crud/DetailsItem";
import SearchBar from "@/components/layouts/SearchBar";
import SimpleTextField from "@/components/inputs/SimpleTextField";
import SimpleSelect from "@/components/inputs/SimpleSelect";
import CustomLabel from "@/components/texts/CustomLabel";
import Indexes from "@/domain/indexes/indexes";
import Phases from "@/domain/phases/phases";
import Records from "@/domain/records/records";
import Cultures from "@/domain/cultures/cultures";

import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";

export default {
  name: "Indexes",
  components: {
    DataTable,
    Toolbar,
    FormDialog,
    RowDetails,
    ColDetails,
    SearchBar,
    SimpleTextField,
    SimpleSelect,
    CustomLabel,
    ConfirmDestroyDialog
  },
  data() {
    return {
      types: [
        {
          text: "Quantitativo"
        },
      ],

      isActiveItems: [
        {
          text: "Ativo",
          value: true,
        },
        {
          text: "Inativo",
          value: false,
        },
      ],
      recordTypeItems: [
        {
          name: 'Manejo'
        },
        {
          name: 'Financeiro'
        },
        {
          name: 'Fertilidade'
        },
      ],

      objectName: "índices",
      toolbarName: "Lista de Índices",
      formName: "Adicionar Índice",

      fetching: true,
      selectedRecord: {},
      search: {},
      recordTypeSearch: "",
      data: [],
      phases: [],
      records: [],
      cultures: [],

      indexService: null,
      phaseService: null,
      recordService: null,
      cultureService: null,

      payload: null,
      selected: null,
      canToggleUser: false,

      slug: "producer",
      authority_id: 0,

      openDetails: false,
      formDialog: false,
      showConfirmDestroyDialog: false,
      clearPhase: false,
      
      requiredRules: [v => (!!v || v === false) || "O campo é obrigatório"],
      recordRules: [
        () => {
          const type = this.selectedRecord.type;

          if (!type) {
            return "O campo é obrigatório";
          } else {
            return true;
          }
        }
      ],
      phaseRules: [
        () => {
          if (!this.payload.phase_id) {
            return "O campo é obrigatório";
          } else {
            return true;
          }
        }
      ]
    };
  },
  methods: {
    getIndexes() {
      this.fetching = true;
      this.data     = [];

      this.indexService.list().then((result) => {
        result.data.map((itm) => {
          this.data.push(itm);
        });
      })
      .catch(({response}) => {
        const {error} = response.data;
        this.$toasted.show(error, {
          type: "error",
        });
      });

      this.fetching = false;
    },
    async getPhases() {
      this.phases = [];
      let loader = this.$loading.show();

      if (this.clearPhase) {
        this.payload.phase_id = null;
        this.clearPhase = false;
      }

      await this.phaseService.getByRecord(this.selectedRecord.id).then((result) => {
        result.data.map((itm) => {
          this.phases.push(itm);
        });
      });

      if (this.selectedRecord.type !== 'Manejo') {
        this.payload.phase_id = this.phases[0].id;
        this.clearPhase = true;
      }

      loader.hide();
    },
    getRecords() {
      let loader   = this.$loading.show();
      this.records = [];

      this.recordService.all().then((result) => {
        result.data.map((itm) => {
          this.records.push(itm);
        });
      });

      loader.hide();
    },
    async getRecordsByCulture() {
      let loader   = this.$loading.show();
      this.records = [];

      await this.recordService.listByCulture(this.payload.culture_id)
      .then((result) => {
        result.forEach((itm) => {
          this.records.push(itm);
        });
      });

      this.selectedRecord = this.records.find(
        item => item.type === this.selectedRecord.type
      );

      this.getPhases();
      loader.hide();
    },
    getCultures() {
      let loader    = this.$loading.show();
      this.cultures = [];

      this.cultureService.all().then((result) => {
        result.data.map((itm) => {
          this.cultures.push(itm);
        });
      });

      loader.hide();
    },
    getStatus(status) {
      if (status === true) return "Ativo";

      return "Inativo";
    },
    changeCulture() {
      this.payload.phase_id = null;
      this.getRecordsByCulture();
    },
    initialize() {
      this.indexService = Indexes;
      this.phaseService = Phases;
      this.recordService = Records;
      this.cultureService = Cultures;
      this.payload = this.indexService.newData();

      this.getIndexes();
      this.getRecords();
      this.getCultures();
    },
    view(item) {
      this.payload = Object.assign({}, item);
      this.selected = Object.assign({}, item);
      this.openDetails = true;
    },
    closeDetails() {
      this.payload = {};
      this.selected = {};
      this.openDetails = false;
    },
    create() {
      this.payload = {};
      this.selectedRecord = {};
      this.selected = {};
      this.clearPhase = false;
      this.types = [{text: "Quantitativo"}];
      this.formDialog = true;
    },
    edit() {
      this.types = [{text: "Quantitativo"}];
      this.formDialog = true;
      this.payload.culture_id = this.payload.phase.record.culture_id;
      this.selectedRecord = this.payload.phase.record;
      this.clearPhase = false;

      if (this.payload.type === "Qualitativo") {
        this.types.push({text: "Qualitativo"});
      }

      this.getRecordsByCulture();
    },
    deleteItem(item) {
      this.payload = item;
      this.showConfirmDestroyDialog = true;
    },
    closeConfirmDestroyDialog() {
      this.showConfirmDestroyDialog = false;
    },
    closeFormDialog() {
      this.selected = {};
      this.formDialog = false;
    },
    closeView() {
      this.openDetails = false;
      this.initialize();
    },
    save() {
      if (!this.$refs.formIndex.validate()) {
        return;
      }

      this.payload.record_id = this.selectedRecord.id;
      let loader = this.$loading.show();

      if (!this.openDetails) {
        this.indexService
            .create(this.payload)
            .then(() => {
              loader.hide();
              this.closeFormDialog();
              this.initialize();
              this.$toasted.show("Índice cadastrado com sucesso.", {
                type: "success",
              });
            })
            .catch(({response}) => {
              loader.hide();
              const {error} = response.data;
              this.$toasted.show(error, {
                type: "error",
              });
            });
      } else {
        this.indexService
            .edit(this.payload)
            .then(() => {
              loader.hide();

              const recordSelected = this.records.find(
                item => item.id === this.payload.record_id
              );

              const phaseSelected = this.phases.find(
                item => item.id === this.payload.phase_id
              );

              this.payload.phase.record_id         = this.payload.record_id;
              this.payload.phase.record.id         = this.payload.record_id;
              this.payload.phase.record.type       = recordSelected.type;
              this.payload.phase.record.culture_id = this.payload.culture_id;

              this.payload.phase.id   = this.payload.phase_id;
              this.payload.phase.name = phaseSelected.name;

              this.closeFormDialog();
              this.$toasted.show("Índice atualizado com sucesso.", {
                type: "success",
              });
            })
            .catch(({response}) => {
              loader.hide();
              const {error} = response;
              this.$toasted.show(error, {
                type: "error",
              });
            });
      }
    },
    async destroy() {
      const loader = this.$loading.show();

      await this.indexService.delete(this.payload).then(() => {
        loader.hide();
        this.getIndexes();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Índice removido com sucesso.", {
          type: "success",
        });
      })
      .catch(() => {
        loader.hide();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Índice não foi removido.", {
          type: 'error'
        });
      });
    },
    filterByRecordType() {
      this.search.record = this.recordTypeSearch.name;
    },
    clearFilters() {
      this.recordTypeSearch = null;
    },
    loadAgain() {
      this.initialize();
    }
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: this.canBeSortable,
        },
        {text: "Índice", value: "name", sortable: this.canBeSortable},
        {
          text: "Registro",
          value: "phase.record.type",
          sortable: this.canBeSortable,
          filter: value => {
            if (!this.recordTypeSearch) return true
            return value === this.search.record;
          }
        },
        {text: "Fase", value: "phase.name", sortable: this.canBeSortable},
        {text: "Tipo", value: "type", sortable: this.canBeSortable},
        {text: "Detalhes", value: "actions", sortable: false},
      ]
    },
    canBeSortable() {
      return !this.fetching;
    },
    dynamicToolbarName() {
      if (this.openDetails) {
        return this.toolbarName + " / Detalhes";
      }

      return this.toolbarName;
    },
    dynamicFormName() {
      if (this.openDetails) {
        return "Editar Índice";
      }

      return this.formName;
    },
  },
  beforeMount() {
    this.initialize();
  },
};
</script>