import IndexesService from "./indexes.service";

class Index {
    constructor(payload = {}) {
        Object.assign(this, {
            ...Index.build(payload),
        });
    }

    static build(
        {
            id = '',
            name = '',
            index_id = '',
            record_id = '',
            phase_id = '',
            status = '',
            type = '',
            phase = {},
        }
    ) {
        return {
            id,
            name,
            index_id,
            record_id,
            phase_id,
            status,
            type,
            phase,
        }
    }

    static newData(payload) {
        return new Index(payload);
    }

    static list(params) {
        return new Promise((resolve, reject) => {
            IndexesService
                .list(params)
                .then((success) => {
                    const {data} = success;
                    const list = Object.assign({}, {...data});
                    list.data = data.data.map(item => this.newData(item));
                    resolve(list);
                }, (error) => {
                    reject(error);
                })
        });
    }

    static create(payload) {
        return new Promise((resolve, reject) => {
            IndexesService
                .create(payload)
                .then((success) => {
                    resolve(success);
                }, (error) => {
                    reject(error);
                });
        });
    }

    static edit(payload = {}) {
        return new Promise((resolve, reject) => {
            IndexesService
                .edit(payload)
                .then((success) => {
                    resolve(success);
                }, (error) => {
                    reject(error);
                });
        });
    }

    static toggle(id) {
        return new Promise((resolve, reject) => {
            IndexesService
                .toggle(id)
                .then((success) => {
                    resolve(success);
                }, (error) => {
                    reject(error);
                });
        });
    }

    static delete(payload) {
        return new Promise((resolve, reject) => {
            IndexesService
            .delete(payload)
            .then(success => {
                resolve(success);
            }, error => {
                reject(error);
            });
        });
      }
}

export default Index;
