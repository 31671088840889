import PhasesService from "./phases.service";

class Phase {
    constructor(payload = {}) {
        Object.assign(this, {
            ...Phase.build(payload),
        });
    }

    static build(
        {
            id = '',
            name = '',
            record = {},
            indexes = {},
        }
    ) {
        return {
            id,
            name,
            record,
            indexes,
        };
    }

    static newData(payload) {
        return new Phase(payload);
    }

    static list(params) {
        return new Promise((resolve, reject) => {
            PhasesService
                .list(params)
                .then((success) => {
                    const {data} = success;
                    const list = Object.assign({}, {...data});
                    list.data = data.data.map(item => this.newData(item));
                    resolve(list);
                }, (error) => {
                    reject(error);
                })
        });
    }
    static all(params) {
        return new Promise((resolve, reject) => {
            PhasesService
                .all(params)
                .then((success) => {
                    const {data} = success;
                    const list = Object.assign({}, {...data});
                    list.data = data.data.map(item => this.newData(item));
                    resolve(list);
                }, (error) => {
                    reject(error);
                })
        });
    }
    static getByRecord(recordId) {
        return new Promise((resolve, reject) => {
            PhasesService
                .getByRecord(recordId)
                .then((success) => {
                    const {data} = success;
                    const list = Object.assign({}, {...data});
                    list.data = data.data.map(item => this.newData(item));
                    resolve(list);
                }, (error) => {
                    reject(error);
                })
        });
    }
}

export default Phase;
