import http from "@/services/http.service";

const apiUrl = "/phases";

class PhasesService {
  static list(params) {
    return http.get(`${apiUrl}`, { params });
  }
  static all(params) {
    return http.get(`${apiUrl}-all`, { params });
  }
  static getByRecord(recordId) {
    return http.get(`${apiUrl}/records/${recordId}`);
  }
}

export default PhasesService;
